import "./style.css";
import * as THREE from "three";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls";
import TypeIt from "typeit";

/**
 * Scene
 */
const scene = new THREE.Scene();
const canvas = document.querySelector(".webgl");
scene.background = new THREE.Color(0x000000);

/**
 * Objects
 */
const geometry = new THREE.TorusGeometry(4, 1.3, 16, 100);
const material = new THREE.MeshBasicMaterial({
  color: 0xccccccc,
  wireframe: true,
});
const mesh = new THREE.Mesh(geometry, material);
scene.add(mesh);

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", (e) => {
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(2, window.devicePixelRatio));
});

window.addEventListener("dblclick", (e) => {
  if (document.fullscreenElement) document.exitFullscreen();
  else canvas.requestFullscreen();
});

/**
 * Camera
 */
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height);
camera.position.z = 20;

/**
 * Controls
 */
const controls = new TrackballControls(camera, canvas);
controls.rotateSpeed = 0.3;
controls.noPan = true;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas,
  antialias: true,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(2, window.devicePixelRatio));

/**
 * Animate
 */
mesh.rotation.reorder("ZYX");
mesh.rotation.z = Math.PI / 3.5;
const clock = new THREE.Clock();
const tick = () => {
  const delta = clock.getDelta();
  mesh.rotation.x += delta / 4.5;
  controls.update();
  renderer.render(scene, camera);
  window.requestAnimationFrame(tick);
};

tick();

/**
 * Typing animation
 */
new TypeIt(".title", {
  speed: 50,
  startDelay: 900,
  strings: "Hi, I'm Jason H.",
  waitUntilVisible: true,
  afterComplete: (_, instance) => instance.destroy(),
}).go();
